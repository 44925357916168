import React, { useState } from "react"
import { animated, useSpring } from "react-spring"
import { Waypoint } from "react-waypoint"

const InvestorStack = () => {
  const [on, toggle] = useState(false)

  const bottomsquare = useSpring({
    transform: on ? "translate3d(-50px, -20px, 0px)" : "translate3d(10px, 0px,0)",
    opacity: on ? 1 : 0
  })
  const topsquare = useSpring({
    transform: on
      ? "translate3d(-50px, -20px, 0px)"
      : "translate3d(10px, 0px, 0)",
    opacity: on ? 1 : 0
  })
  const platform = useSpring({
    opacity: on ? 1 : 0
  })

  return (
    <div>
      <Waypoint
        bottomOffset='25%'
        // bottom of window
        onEnter={() => {
          if (!on) toggle(true)
        }}
      />
      <svg
        id='stack'
        xmlns='http://www.w3.org/2000/svg'
        viewBox="0 0 603 484.7"
      >
        <title>3DStack</title>
        <animated.g style={platform} id='platform'>
          <path id="grayplatform" fill="#E6E6E5" d="M607 319.4L309.6 484.7 0 313.6l311.9-152.8L607 319.4z"/>
          <g id="dkbluebottom">
            <path fill="#30648B" d="M416.952 273.4l-113.4 57.7-113.4-57.7 113.4-52.2 113.4 52.2z"/>
            <path fill="#0B436A" d="M304.552 380.8l-1-49.7-113.4-57.7 1 47.2 113.4 60.2z"/>
            <path fill="#285A7C" d="M304.552 380.8l-1-49.7 112.9-57.7 1 48.6-112.9 58.8z"/>
          </g>
        </animated.g>
        <animated.g style={bottomsquare} id='ltbluebottom'>
          <path fill="#AFD8ED" d="M416.452 208.7l-113.4 57.7-113.4-57.7 113.4-52.2 113.4 52.2z"/>
          <path fill="#8DC8E8" d="M304.052 316.2l-1-49.7-113.4-57.8 1 47.2 113.4 60.3z"/>
          <path fill="#9ECFEA" d="M304.052 316.2l-1-49.7 112.9-57.7 1 48.6-112.9 58.8z"/>
        </animated.g>
        <animated.g style={platform} id="dkbluetop">
          <path fill="#30648B" d="M416.952 143.9l-113.4 57.7-113.4-57.7 113.4-52.2 113.4 52.2z"/>
          <path fill="#0B436A" d="M304.552 251.4l-1-49.7-113.4-57.8 1 47.2 113.4 60.3z"/>
          <path fill="#285A7C" d="M304.552 251.4l-1-49.7 112.9-57.7 1 48.6-112.9 58.8z"/>
        </animated.g>
        <animated.g style={topsquare} id='ltbluetop'>
          <path fill="#AFD8ED" d="M416.452 80.2l-113.4 57.7-113.4-57.7 113.4-52.2 113.4 52.2z"/>
          <path fill="#8DC8E8" d="M304.052 187.6l-1-49.7-113.4-57.7 1 47.2 113.4 60.2z"/>
          <path fill="#9ECFEA" d="M304.052 187.6l-1-49.7 112.9-57.7 1 48.6-112.9 58.8z"/>
        </animated.g>
      </svg>
    </div>
  )
}

export default InvestorStack
