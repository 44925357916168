import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Img from 'gatsby-image';
import { animated, useSpring } from 'react-spring';
import { Container, Row, Col } from 'reactstrap';
import IconsOppo from '../components/icons/investors/iconOpportunities';
import SmallERotate from '../components/icons/animation/eRotation-small';
import InvestorStack from '../components/icons/animation/InvestorStack';
import HowWeWorkV2 from '../components/investors/hwwAccordionV2';
import SideArrow from '../components/icons/arrows/SideArrow';
import { Parallax, ParallaxLayer } from 'react-spring/renderprops-addons';
import { Waypoint } from 'react-waypoint';

const Investors = ({ data }) => {
    // eslint-disable-next-line
    let parallax;
    const { image1, image2 } = data;

    const [on, toggle] = useState(false);

    const fadein = useSpring({
        opacity: on ? 1 : 0,
        from: { opacity: 0, transform: 'translate3d(0,50px,0)' },
        to: {
            opacity: on ? 1 : 0,
            transform: on ? 'translate3d(0,0,0px)' : 'translate3d(0,50px,0)',
        },
        config: { tension: 100 },
    });
    const topphoto = useSpring({
        //opacity: on ? 1 : 0,
        width: on ? '100%' : '0%',
        overflow: on ? 'hidden' : 'hidden',
        config: { tension: 100 },
    });

    return (
        <Parallax ref={(ref) => (parallax = ref)}>
            <Layout>
                <SEO
                    title={data.wpgraphql.page.title}
                    description={data.wpgraphql.page.acf_page_meta.metadescription}
                />
                <article className="page-investors">
                    <header className="header--fullwidth header--darkbg">
                        {data.wpgraphql.page.featuredImage != null && (
                            <Img
                                className="header__image"
                                fluid={
                                    data.wpgraphql.page.featuredImage.node.imageFile.childImageSharp
                                        .fluid
                                }
                            />
                        )}
                        <Container>
                            <Row>
                                <Col xs={{ offset: 1 }}>
                                    <h1 className="heading1 header__message">
                                        {/* {data.wpgraphql.page.acf_investor.heroHead} */}
                                        <strong>PRESERVATION FIRST,</strong>
                                        <br />
                                        APPRECIATION SECOND.
                                    </h1>
                                </Col>
                            </Row>
                        </Container>
                    </header>
                    <Container>
                        <section className="section__intro">
                            <Row>
                                <Col
                                    xs={{ size: 14 }}
                                    md={{ size: 6 }}
                                    xl={{ size: 5 }}
                                    className="mb-4 mb-lg-0 investing-col"
                                >
                                    <h2 className="heading2">
                                        {data.wpgraphql.page.acf_investor.heroSubhead}
                                    </h2>
                                    <p className="body-copy">
                                        Our average annualized return on full-cycle investments is
                                        over 30%. We focus on assets with strong fundamentals and a
                                        high margin of safety. We always co-invest in every fund we
                                        create so our interest are closely aligned with our
                                        investor’s interests.
                                    </p>
                                    <div>
                                        <a
                                            target="__blank"
                                            href="https://investors.appfolioim.com/idealcapgroup/investor/contact-us"
                                            className="btn btn-primary"
                                        >
                                            Become an Investor
                                        </a>
                                        <br />
                                        <a
                                            target="__blank"
                                            href="https://investors.appfolioim.com/idealcapgroup/investor"
                                            className="btn btn-primary d-inline-block mt-3"
                                        >
                                            Investor Login
                                        </a>
                                    </div>
                                </Col>
                                <Col
                                    className="overlapped-images"
                                    xs={{ size: 14 }}
                                    md={{ size: 6, offset: 2 }}
                                    xl={{ size: 5, offset: 1 }}
                                >
                                    <Img
                                        className="overlapped-images__back"
                                        fixed={image1.childImageSharp.fixed}
                                        alt={''}
                                    />
                                    <Img
                                        className="overlapped-images__front"
                                        fixed={image2.childImageSharp.fixed}
                                        alt={''}
                                    />
                                </Col>
                            </Row>
                        </section>
                        <section className="section__investment-opportunities">
                            <Row className="no-gutters">
                                <Col
                                    xs={{ size: 12, offset: 1 }}
                                    sm={{ offset: 0 }}
                                    md={{ size: 4 }}
                                    lg={{ size: 5 }}
                                >
                                    <Row className="no-gutters">
                                        <Col sm={{ offset: 1 }} lg={{ size: 10 }}>
                                            <h2 className="heading2">
                                                {data.wpgraphql.page.acf_investor.section1.head}
                                            </h2>
                                            <p className="body-copy">
                                                {data.wpgraphql.page.acf_investor.section1.text}
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col md={{ size: 9 }}>
                                    <Row className="no-gutters">
                                        <Col
                                            sm={{ size: 8, offset: 3 }}
                                            md={{ size: 12, offset: 1 }}
                                            lg={{ offset: 0 }}
                                        >
                                            <IconsOppo />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </section>
                    </Container>
                    <ParallaxLayer
                        offset={-0.2}
                        speed={0.15}
                        className="parallax__arrow-container d-none d-md-block"
                    >
                        <SideArrow className="parallax__arrow-right" />
                    </ParallaxLayer>
                    <section className="section__how-we-work">
                        <Container>
                            <Row>
                                <Col
                                    sm={{ size: 10, offset: 2 }}
                                    md={{ size: 4, offset: 0 }}
                                    lg={{ size: 4, offset: 1 }}
                                >
                                    <h2 className="heading2">
                                        {data.wpgraphql.page.acf_investor.section2.head}
                                    </h2>
                                    <p className="body-copy">
                                        {data.wpgraphql.page.acf_investor.section2.text}
                                    </p>
                                </Col>
                                <Col
                                    md={{ size: 5 }}
                                    lg={{ size: 4 }}
                                    className="icon__ideal-e d-none d-md-block"
                                >
                                    <SmallERotate className="w-75" />
                                </Col>
                                <Col
                                    sm={{ size: 10, offset: 2 }}
                                    md={{ size: 5, offset: 0 }}
                                    lg={{ size: 4 }}
                                >
                                    <HowWeWorkV2 />
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <Container>
                        <section className="section__customized-funds">
                            <Row>
                                <Col md={{ size: 8 }} lg={{ size: 6, offset: 1 }}>
                                    <div>
                                        <h2 className="heading2">
                                            {data.wpgraphql.page.acf_investor.section3.head}
                                        </h2>
                                        <p className="body-copy">
                                            {data.wpgraphql.page.acf_investor.section3.text}
                                        </p>
                                        <p className="body-copy">We handle all in-house:</p>
                                        <ul className="bulleted-list">
                                            <Row>
                                                <Col sm={{ size: 6 }} md={{ size: 6 }}>
                                                    <li>Asset management</li>
                                                    <li>Reporting</li>
                                                    <li>Tax information</li>
                                                    <li>Construction management</li>
                                                </Col>
                                                <Col sm={{ size: 6 }} md={{ size: 6, offset: 1 }}>
                                                    <li>Fund Strategy</li>
                                                    <li>Underwriting</li>
                                                    <li>Acquisitions</li>
                                                    <li>Capital Stack Optimization</li>
                                                </Col>
                                            </Row>
                                            {/* {data.wpgraphql.page.acf_investor.section3.bullets.map(
                  bullets => (
                    <li>{bullets.bullet}</li>
                  )
                )} */}
                                        </ul>
                                    </div>
                                </Col>
                                <Col md={{ size: 6 }}>
                                    <InvestorStack />
                                </Col>
                            </Row>
                        </section>
                    </Container>
                </article>
            </Layout>
        </Parallax>
    );
};

export default Investors;

export const query = graphql`
    query {
        image1: file(relativePath: { in: "investing-photo-1.jpeg" }) {
            childImageSharp {
                fixed(width: 500, height: 500) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        image2: file(relativePath: { in: "investing-photo-2.jpeg" }) {
            childImageSharp {
                fixed(width: 500, height: 500) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        wpgraphql {
            page(id: "cGFnZToxMg==") {
                title
                acf_page_meta {
                    metadescription
                }
                acf_investor {
                    heroHead
                    heroSubhead
                    heroText
                    section1 {
                        head
                        text
                    }
                    section2 {
                        head
                        text
                        block1Title
                        block1Text
                        block2Title
                        block2Text
                        block3Title
                        block3Text
                    }
                    section3 {
                        head
                        text
                        bulletHead
                        bullets {
                            bullet
                        }
                    }
                }
                featuredImage {
                    node {
                        sourceUrl
                        imageFile {
                            childImageSharp {
                                fluid(maxWidth: 1600, quality: 90) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
