import React, { Component } from 'react';
import { StaticQuery, graphql } from "gatsby"
import {Button, Card, CardBody, Collapse } from 'reactstrap';


const HOWWEWORK_QUERYV2 = graphql`
  query HowWorkQueryV2 {
    wpgraphql {
      page(id: "cGFnZToxMg==") {
        acf_investor {
          section2 {
            head
            text
            block1Title
            block1Text
            block2Title
            block2Text
            block3Title
            block3Text
          }
        }
      }
    }
  }
`
class HowWeWorkV2 extends Component {

  constructor(props) {
    super(props);
    this.toggleAccordion = this.toggleAccordion.bind(this);
    this.state = {
      accordion: [false, false, false]
    };
  }

  toggleAccordion(tab) {

    const prevState = this.state.accordion;
    const state = prevState.map((x, index) => tab === index ? !x : false);

    this.setState({
      accordion: state,
    });
  }

  render() {
    return (

      <StaticQuery
        query={HOWWEWORK_QUERYV2}
        render={data => {
          return (
            <div className='how-we-work__cards'>
              <div className='how-we-work__card'>
                <h4 className='heading4 d-md-none'>
                  {data.wpgraphql.page.acf_investor.section2.block1Title}
                </h4>
                <Button
                  onClick={() => this.toggleAccordion(0)} aria-expanded={this.state.accordion[0]} aria-controls="collapseOne"
                  color='investors'
                  className={`heading4 d-none d-md-block arrow-open-${this.state.accordion[0]}`}
                >
                  {data.wpgraphql.page.acf_investor.section2.block1Title}
                </Button>
                <Collapse
                  isOpen={this.state.accordion[0]} data-parent="#accordion" id="collapseOne" aria-labelledby="headingOne"
                  className='how-we-work__card-wrapper'
                >
                  <Card>
                    <CardBody className='body-copy px-5'>
                      {data.wpgraphql.page.acf_investor.section2.block1Text}
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
              <div className='how-we-work__card'>
                <h4 className='heading4 d-md-none'>
                  {data.wpgraphql.page.acf_investor.section2.block2Title}
                </h4>
                <Button
                  onClick={() => this.toggleAccordion(1)} aria-expanded={this.state.accordion[1]} aria-controls="collapseTwo"
                  color='investors'
                    className={`heading4 d-none d-md-block arrow-open-${this.state.accordion[1]}`}
                >
                  {data.wpgraphql.page.acf_investor.section2.block2Title}
                </Button>
                <Collapse  
                  isOpen={this.state.accordion[1]} data-parent="#accordion" id="collapseTwo" aria-labelledby="headingTwo"
                  className='how-we-work__card-wrapper'
                >
                  <Card>
                    <CardBody className='body-copy px-5'>
                      {data.wpgraphql.page.acf_investor.section2.block2Text}
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
              <div className='how-we-work__card'>
                <h4 className='heading4 d-md-none'>
                  {data.wpgraphql.page.acf_investor.section2.block3Title}
                </h4>
                <Button
                  onClick={() => this.toggleAccordion(2)} aria-expanded={this.state.accordion[2]} aria-controls="collapseThree"
                  color='investors'
                  className={`heading4 d-none d-md-block arrow-open-${this.state.accordion[2]}`}
                >
                  {data.wpgraphql.page.acf_investor.section2.block3Title}
                </Button>
                <Collapse
                  isOpen={this.state.accordion[2]} data-parent="#accordion" id="collapseThree" aria-labelledby="headingThree"
                  className='how-we-work__card-wrapper'
                >
                  <Card>
                    <CardBody className='body-copy px-5'>
                      {data.wpgraphql.page.acf_investor.section2.block3Text}
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
            </div>
          )
        }}
      />
    );
  }
}

export default HowWeWorkV2