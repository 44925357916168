import React from "react";

const SvgPreservationIcon = props => (
  <svg viewBox="0 0 210 209" {...props}>
    <path
      fill="#77BDE2"
      d="M155.365 148.347H68.022v-42.181h45.164V80.664h42.181l-.002 67.683z"
    />
    <path fill="#77BDE2" d="M93.28 86.16h81.988l-40.994-40.983L93.28 86.16z" />
    <g>
      <path
        fill="#77BDE2"
        d="M70.011 171.456v-21.782H35.979v-44.835H70.011V83.056l2.266 2.267 40.984 40.994.938.939-.938.939-40.984 40.994-2.266 2.267z"
      />
      <path
        fill="#FFF"
        d="M71.339 86.262v19.905H37.307v42.18h34.032v19.903l40.984-40.994-40.984-40.994m-2.655-6.411l4.533 4.534 40.984 40.994 1.877 1.877-1.877 1.877-40.984 40.994-4.533 4.534v-23.659H34.652v-47.49h34.032V79.851z"
      />
    </g>
  </svg>
);

export default SvgPreservationIcon;
