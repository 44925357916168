import React, { useState } from 'react';
import anime from 'animejs';
import SmallAnimatedE from './animatedE-small';
import { Waypoint } from "react-waypoint"

const SmallERotate = () => {
  const animate = () => {
    const loader = anime.timeline({
      targets: '.logo-e',
      delay:  100,
      duration: 200, // Can be inherited
      easing: "linear", // Can be inherited
      //loop: true,
      //autoplay: true
    });

    loader
      .add({
        targets: '#thebigE',
        //translateX: -270,
        opacity: ['0', '1'],
        duration: 1100,
      }, 25)
      .add({
        targets: '#frontBottom',
        points: "10.63 55.12, 10.63 73.06, 48.61 71.85, 48.61 59.86, 10.63 55.12",
        duration: 850,
        easing: "linear",
      //  translateX: 100,
    }, 425)
      .add({
        targets: '#sideBottom',
        points: "0.00 61.82, 10.63 55.12, 10.63 73.06, 0.00 70.51, 0.00 61.82",
        duration: 850,
        easing: "linear",
        opacity: ['0', '1'],
        //translateX: 100,
      }, 425)
      .add({
        targets: '#frontMiddle',
        points: "32.34 33.98, 10.63 27.92, 10.63 45.15, 32.34 49.17, 32.34 33.98",
        duration: 850,
        easing: "linear"
      }, 425)
      .add({
        targets: '#sideMiddle',
        points: "0.00 44.65, 10.63 27.92, 10.63 45.15, 0.00 53.34, 0.00 44.65",
        duration: 850,
        easing: "linear",
        opacity: ['0', '1'],
      }, 425)
      .add({
        targets: '#frontTop',
        points: "48.61 17.95, 10.63 0.00, 10.63 17.23, 48.61 30.56, 48.61 17.95",
        duration: 850,
        easing: "linear"
      }, 425)
      .add({
        targets: '#sideTop',
        points: "0.00 26.45, 10.63 0.00, 10.63 17.23, 0.00 35.13, 0.00 26.45",
        duration: 850,
        easing: "linear",
        opacity: ['0', '1'],
      }, 425)
  }

  const [on, toggle] = useState(false);
    if (on) animate();

  return (
    <>
    <Waypoint
      bottomOffset='15%'
      // bottom of window
       onEnter={() => {
         if (!on) toggle(true)
       }}
       />
      <SmallAnimatedE />
    </>
  );
};


export default SmallERotate;
