import React from "react";

const SvgHistoryIcon = props => (
  <svg viewBox="0 0 210 209" {...props}>
    <path
      fill="#77BDE2"
      d="M104.486 163.767v-18.659c23.997-.008 43.443-19.468 43.435-43.465-.008-23.997-19.468-43.443-43.465-43.435-18.461.006-34.902 11.677-40.998 29.102l-17.617-6.16c11.323-32.385 46.756-49.459 79.141-38.136 32.385 11.323 49.459 46.756 38.136 79.141-8.714 24.923-32.23 41.614-58.632 41.617v-.005z"
    />
    <path
      fill="#77BDE2"
      d="M35.433 77.825l40.104 7.565-23.828 16.263-16.276-23.828zM99.381 101.653h10.212l-5.103-31.705-5.109 31.705z"
    />
    <path
      fill="#77BDE2"
      d="M105.96 97.407l-6.579 6.579 32.491 25.912-25.912-32.491z"
    />
    <g transform="translate(50.234 44.73)">
      <circle fill="#77BDE2" cx={54.252} cy={56.923} r={5.719} />
    </g>
  </svg>
);

export default SvgHistoryIcon;
